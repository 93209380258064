import { render, staticRenderFns } from "./StaffHouse.vue?vue&type=template&id=428ddea6&"
import script from "./StaffHouse.vue?vue&type=script&lang=js&"
export * from "./StaffHouse.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports